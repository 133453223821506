import React from "react";
import "./Popup.css";

function Popup(props) {
  return props.trigger ? (
    <div className="popup">
      {" "}
      <div
        className="popup-clickable-layer"
        onClick={() => props.setTrigger(false)}
      />
      <div className="popup-inner">
        {props.children}
        <div className="close-btn" onClick={() => props.setTrigger(false)}>
          <b>X</b>
        </div>
      </div>
    </div>
  ) : (
    ""
  );
}

export default Popup;
