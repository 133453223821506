import React, { useRef } from "react";
import algoliasearch from "algoliasearch/lite";
import PropTypes from "prop-types";
import "./App.css";
import { useState } from "react";

import {
  InstantSearch,
  // HierarchicalMenu,
  RefinementList,
  // SortBy,
  Pagination,
  ClearRefinements,
  Highlight,
  Hits,
  HitsPerPage,
  Panel,
  Configure,
  SearchBox,
  Snippet,
  // ToggleRefinement,
  Stats,
} from "react-instantsearch-dom";
import {
  ClearFiltersMobile,
  NoResults,
  ResultsNumberMobile,
  SaveFiltersMobile,
} from "./widgets";
import Popup from "./widgets/Popup";
import withURLSync from "./URLSync";
// import { formatNumber } from "./utils";
import "./Theme.css";
import "./App.css";
import "./App.mobile.css";
import "./widgets/Pagination.css";
import middlebyLogo from "./assets/middleby_logo.png";
import middlebyLogoWide from "./assets/middleby.png";
// import stockComputer from "./assets/stock_computer.webp";

//brand images import
import anets from "./assets/middleby_brand_resources/anets.png";
import apwWyott from "./assets/middleby_brand_resources/apw_wyott.png";
import bakersPride from "./assets/middleby_brand_resources/bakerspride.png";
import beechOvens from "./assets/middleby_brand_resources/beechovens.png";
import bki from "./assets/middleby_brand_resources/bki.png";
// import blodgettCombi from "./assets/middleby_brand_resources/blodgett_combi.png";
import blodgett from "./assets/middleby_brand_resources/blodgett.png";
import blodgettPart from "./assets/middleby_brand_resources/blodgett_part.png";
import blodgettProduct from "./assets/middleby_brand_resources/blodgett_product.png";
// import bluezone from "./assets/middleby_brand_resources/bluezone.png";
import doyon from "./assets/middleby_brand_resources/doyon.png";
import carterHoffmann from "./assets/middleby_brand_resources/carter_hoffmann.png";
import concordia from "./assets/middleby_brand_resources/concordia.png";
import cooktek from "./assets/middleby_brand_resources/cooktek.png";
import crown from "./assets/middleby_brand_resources/crown.png";
import deutsche from "./assets/middleby_brand_resources/deutsche.png";
import evo from "./assets/middleby_brand_resources/evo.png";
import firex from "./assets/middleby_brand_resources/firex.png";
import follett from "./assets/middleby_brand_resources/follett.png";
import globe from "./assets/middleby_brand_resources/globe.png";
// import houno from "./assets/middleby_brand_resources/houno.png";
import jade from "./assets/middleby_brand_resources/jade.png";
import josper from "./assets/middleby_brand_resources/josper.png";
// import lab2fab from "./assets/middleby_brand_resources/lab2fab.png";
import lang from "./assets/middleby_brand_resources/lang.png";
// import lincat from "./assets/middleby_brand_resources/lincat.png";
import magikitchn from "./assets/middleby_brand_resources/magikitch'n.png";
import marketforge from "./assets/middleby_brand_resources/marketforge.png";
import marsal from "./assets/middleby_brand_resources/marsal.png";
// import marvel from "./assets/middleby_brand_resources/marvel.png";
import middlebymarshall from "./assets/middleby_brand_resources/middlebymarshall.png";
import nieco from "./assets/middleby_brand_resources/nieco.png";
import nuvu from "./assets/middleby_brand_resources/nu_vu.png";
import perfectFryCompany from "./assets/middleby_brand_resources/perfectfrycompany.png";
import pitco from "./assets/middleby_brand_resources/pitco.png";
// import powerhouseDynamics from "./assets/middleby_brand_resources/powerhousedynamics.png";
// import qualserv from "./assets/middleby_brand_resources/qualserv.png";
import southbend from "./assets/middleby_brand_resources/southbend.png";
import star from "./assets/middleby_brand_resources/star.png";
// import svebadahlen from "./assets/middleby_brand_resources/svebadahlen.png";
// import synesso from "./assets/middleby_brand_resources/synesso.png";
import taylor from "./assets/middleby_brand_resources/taylor.png";
import toastMaster from "./assets/middleby_brand_resources/toastmaster.png";
import turbochef from "./assets/middleby_brand_resources/turbochef.png";
import ulinecommercial from "./assets/middleby_brand_resources/ulinecommercial.png";
import ultrafryer from "./assets/middleby_brand_resources/ultrafryer.png";
import varimixer from "./assets/middleby_brand_resources/varimixer.png";
import wells from "./assets/middleby_brand_resources/wells.png";
// import wildGooseFilling from "./assets/middleby_brand_resources/wildgoosefilling.png";
// import wunderBar from "./assets/middleby_brand_resources/wunder_bar.png";

const searchClient = algoliasearch(
  "M1M7Y1OAF3",
  "2c25ad0af4b6461783a9e1b6194155ee"
);

function GetVendorImage(hit) {
  if (hit.Model === "Blodgett 2021 Part Price List") {
    return blodgettPart;
  }
  if (hit.Model === "Blodgett 2021 Product Price List") {
    return blodgettProduct;
  }
  switch (hit["Vendor Name"].trim()) {
    case "ANETS":
      return anets;
    case "APW Wyott":
      return apwWyott;
    case "Bakers Pride":
      return bakersPride;
    case "Beech Ovens":
      return beechOvens;
    case "BKI":
      return bki;
    case "Blodgett":
      return blodgett;
    case "Doyon Baking Equipment":
    case "Doyon Canada":
      return doyon;
    case "Carter-Hoffmann":
      return carterHoffmann;
    case "Concordia Beverage Systems":
      return concordia;
    case "CookTek":
      return cooktek;
    case "Crown":
      return crown;
    case "Deutsche":
      return deutsche;
    case "Evo America, LLC":
      return evo;
    case "Firex":
      return firex;
    case "Follett LLC":
      return follett;
    case "Globe":
    case "Globe Canada":
      return globe;
    case "Jade Range":
      return jade;
    case "Josper Charcoal Ovens":
      return josper;
    case "Lang Manufacturing":
      return lang;
    case "Magikitch'n":
      return magikitchn;
    case "Market Forge":
      return marketforge;
    case "Marsal":
      return marsal;
    case "Middleby Marshall":
      return middlebymarshall;
    case "Nieco Corporation":
      return nieco;
    case "NU-VU":
    case "NU-VU Canada":
      return nuvu;
    case "Perfect Fry Company":
      return perfectFryCompany;
    case "Pitco Frialator":
      return pitco;
    case "Southbend":
      return southbend;
    case "Star":
      return star;
    case "Taylor Company":
      return taylor;
    case "Toastmaster":
      return toastMaster;
    case "TurboChef":
      return turbochef;
    case "U-Line Corporation":
      return ulinecommercial;
    case "Ultrafryer Systems":
      return ultrafryer;
    case "Varimixer":
      return varimixer;
    case "Wells":
      return wells;
    default:
      return middlebyLogoWide;
  }
}

const Hit = ({ hit }) => {
  const [buttonPopup, setButtonPopup] = useState(false);
  return (
    <div>
      <div
        className="cursorPointer"
        onClick={() => {
          if (
            hit.Model === "Blodgett 2021 Part Price List" ||
            hit.Model === "Blodgett 2021 Product Price List"
          ) {
            window.location.href = hit.Link;
          } else {
            setButtonPopup(true);
          }
        }}
      >
        <article className="hit">
          <header className="hit-image-container">
            <img
              src={GetVendorImage(hit)}
              className="hit-image"
              alt={hit.Spec}
            />
          </header>

          <Configure
            attributesToSnippet={["Spec:10"]}
            snippetEllipsisText="…"
            removeWordsIfNoResults="allOptional"
          />

          <div className="hit-info-container">
            {/* <p className="hit-category">
        <Highlight attribute="SuperCategory" hit={hit} />
      </p> */}
            <h1>
              <Highlight attribute="Model" hit={hit} />
            </h1>
            <p className="hit-description">
              <Snippet attribute="Spec" hit={hit} />
            </p>
          </div>
        </article>
      </div>
      <Popup trigger={buttonPopup} setTrigger={setButtonPopup}>
        <h3>{hit.Model}</h3>
        <div className="smallFont">
          <p>
            <b>Brand:</b> {hit["Vendor Name"]}
          </p>
          <p>
            <b>Type:</b> {hit["Product Type"]}
          </p>
          <p>
            <b>Equipment Type:</b> {hit["SuperCategory"]}
          </p>
          <p>
            <b>Equipment Category:</b> {hit.SubSuperCategory}
          </p>
          <p>
            <b>Product Category:</b> {hit["Product Category"]}
          </p>
          <p>
            <b>Description:</b> {hit.Spec}
          </p>
          <p>
            <b>Weight:</b> {hit.Weight}
          </p>
          <p>
            <b>Cube:</b> {hit.Cube}
          </p>
          <p>
            <b>Height:</b> {hit.Height}
          </p>
          <p>
            <b>Width:</b> {hit.Width}
          </p>
          <p>
            <b>Depth:</b> {hit.Depth}
          </p>
          <p>
            <b>Selling Unit:</b> {hit["Selling_Unit"]}
          </p>
          <p>
            <b>Per Case:</b> {hit.PerCase}
          </p>
          <p>
            <b>Freight Class:</b> {hit.Freight_Class}
          </p>
          <p>
            <b>FOB City:</b> {hit.FOB_City}
          </p>
          <p>
            <b>FOB State:</b> {hit.FOB_State}
          </p>
          <p>
            <b>FOB Postal Code:</b> {hit.FOB_PostalCode}
          </p>
        </div>
      </Popup>
    </div>
  );
};

const App = (props) => {
  const containerRef = useRef(null);
  const headerRef = useRef(null);
  function openFilters() {
    document.body.classList.add("filtering");
    window.scrollTo(0, 0);
    window.addEventListener("keyup", onKeyUp);
    window.addEventListener("click", onClick);
  }

  function closeFilters() {
    document.body.classList.remove("filtering");
    containerRef.current.scrollIntoView();
    window.removeEventListener("keyup", onKeyUp);
    window.removeEventListener("click", onClick);
  }

  function onKeyUp(event) {
    if (event.key !== "Escape") {
      return;
    }

    closeFilters();
  }

  function onClick(event) {
    if (event.target !== headerRef.current) {
      return;
    }

    closeFilters();
  }
  return (
    //for some reason searchbox throws error
    <InstantSearch
      searchClient={searchClient}
      indexName="prod_NAME"
      searchState={props.searchState}
      createURL={props.createURL}
      onSearchStateChange={props.onSearchStateChange}
    >
      <header className="header" ref={headerRef}>
        {/* <p className="header-logo">
          <img src={middlebyLogo} alt="Middleby Product Search"/>
        </p> */}
        <img
          className="headerImage"
          src={middlebyLogo}
          alt="Middleby Product Search"
        />
        {/* <p className="header-title">Middleby Product Search</p> */}

        <SearchBox
          translations={{
            placeholder: "Search by model number, brand, equipment, …",
          }}
          submit={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              viewBox="0 0 18 18"
            >
              <g
                fill="none"
                fillRule="evenodd"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="1.67"
                transform="translate(1 1)"
              >
                <circle cx="7.11" cy="7.11" r="7.11" />
                <path d="M16 16l-3.87-3.87" />
              </g>
            </svg>
          }
        />
      </header>

      <main className="container" ref={containerRef}>
        <div className="container-wrapper">
          <section className="container-filters" onKeyUp={onKeyUp}>
            <div className="container-header">
              <h2>Filters</h2>
              <div className="clear-filters" data-layout="desktop">
                <ClearRefinements
                  translations={{
                    reset: (
                      <>
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="11"
                          height="11"
                          viewBox="0 0 11 11"
                        >
                          <g fill="none" fillRule="evenodd" opacity=".4">
                            <path d="M0 0h11v11H0z" />
                            <path
                              fill="#000"
                              fillRule="nonzero"
                              d="M8.26 2.75a3.896 3.896 0 1 0 1.102 3.262l.007-.056a.49.49 0 0 1 .485-.456c.253 0 .451.206.437.457 0 0 .012-.109-.006.061a4.813 4.813 0 1 1-1.348-3.887v-.987a.458.458 0 1 1 .917.002v2.062a.459.459 0 0 1-.459.459H7.334a.458.458 0 1 1-.002-.917h.928z"
                            />
                          </g>
                        </svg>
                        Clear filters
                      </>
                    ),
                  }}
                />
              </div>
              <div className="clear-filters" data-layout="mobile">
                <ResultsNumberMobile />
              </div>
            </div>

            <div className="container-body">
              <Panel header="Brand">
                <RefinementList
                  attribute="Vendor Name"
                  showMore={true}
                  searchable={true}
                  translations={{
                    placeholder: "Search for brands...",
                  }}
                />
              </Panel>
              <Panel header="Type">
                {/* <HierarchicalMenu
                  attributes={["SuperCategory.lvl0", "SubSuperCategory.lvl1"]}
                /> */}
                <RefinementList
                  attribute="Product Type"
                  translations={{
                    placeholder: "Search for equipment types...",
                  }}
                />
              </Panel>
              <Panel header="Solution">
                <RefinementList attribute="Solution" />
              </Panel>
              <Panel header="Equipment Type">
                {/* <HierarchicalMenu
                  attributes={["SuperCategory.lvl0", "SubSuperCategory.lvl1"]}
                /> */}
                <RefinementList
                  attribute="SuperCategory"
                  showMore={true}
                  searchable={true}
                  translations={{
                    placeholder: "Search for equipment types...",
                  }}
                />
              </Panel>
              <Panel header="Equipment Category">
                {/* <HierarchicalMenu
                  attributes={["SuperCategory.lvl0", "SubSuperCategory.lvl1"]}
                /> */}
                <RefinementList
                  attribute="SubSuperCategory"
                  showMore={true}
                  searchable={true}
                  translations={{
                    placeholder: "Search for equipment types...",
                  }}
                />
              </Panel>
            </div>
          </section>

          <footer className="container-filters-footer" data-layout="mobile">
            <div className="container-filters-footer-button-wrapper">
              <ClearFiltersMobile containerRef={containerRef} />
            </div>

            <div className="container-filters-footer-button-wrapper">
              <SaveFiltersMobile onClick={closeFilters} />
            </div>
          </footer>
        </div>

        <section className="container-results">
          <header className="container-header container-options">
            {/* <SortBy
              className="container-option"
              defaultRefinement="prod_NAME"
              items={[
                {
                  label: "Sort by featured",
                  value: "prod_NAME",
                },
              ]}
            /> */}
            <Stats className="container-option" />
            <HitsPerPage
              className="container-option"
              items={[
                // {
                //   label: "16 hits per page",
                //   value: 16,
                // },
                {
                  label: "32 hits per page",
                  value: 32,
                },
                {
                  label: "64 hits per page",
                  value: 64,
                },
              ]}
              defaultRefinement={32}
            />
          </header>
          <Hits hitComponent={Hit} />
          <NoResults />

          <footer className="container-footer">
            <Pagination
              padding={2}
              showFirst={false}
              showLast={false}
              translations={{
                previous: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                  >
                    <g
                      fill="none"
                      fillRule="evenodd"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.143"
                    >
                      <path d="M9 5H1M5 9L1 5l4-4" />
                    </g>
                  </svg>
                ),
                next: (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    viewBox="0 0 10 10"
                  >
                    <g
                      fill="none"
                      fillRule="evenodd"
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="1.143"
                    >
                      <path d="M1 5h8M5 9l4-4-4-4" />
                    </g>
                  </svg>
                ),
              }}
            />
          </footer>
        </section>
      </main>
      <aside data-layout="mobile">
        <button
          className="filters-button"
          data-action="open-overlay"
          onClick={openFilters}
        >
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 14">
            <path
              d="M15 1H1l5.6 6.3v4.37L9.4 13V7.3z"
              stroke="#fff"
              strokeWidth="1.29"
              fill="none"
              fillRule="evenodd"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          Filters
        </button>
      </aside>
    </InstantSearch>
  );
};

Hit.propTypes = {
  hit: PropTypes.object.isRequired,
};

// export default App;

export default withURLSync(App);
